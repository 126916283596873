<template>
    <defaultSec :title="'企微用户查询'" class="route-customer-wrap">
        <el-form :inline="true" class="demo-form-inline select-wrap" size="mini">
            <el-form-item label="openId">
                <el-input type="text" v-model="formParam.externalUserid" placeholder="输入openId查询 "></el-input>
            </el-form-item>
            <el-form-item label="选择企微" >
                <el-select clearable :popper-append-to-body="false" size="mini" value-key="id"
                           v-model="formParam.qyWeixinUserid">
                    <el-option v-for="user in userList" :label="user.name" :value="user.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="customerId">
                <el-input type="text" v-model="formParam.customerId" placeholder="输入customerId查询 "></el-input>
            </el-form-item>
            <el-form-item label="昵称">
                <el-input type="text" v-model="formParam.nickName" placeholder="输入昵称查询 "></el-input>
            </el-form-item>

            <el-form-item>
                <el-button-group>
                    <el-button type="primary" size="mini" @click="searchList">查询</el-button>
                    <el-button type="success" size="mini" v-show="false" @click="exportCustomer">导出</el-button>
                </el-button-group>
            </el-form-item>
        </el-form>
        <tablePagination :total="total" :pageSize="formParam.pageSize" :currentPage="formParam.currentPage" @handlePage="handlePage">
            <el-table v-loading="loading" :height="tableHeight" :data="tableList" border size="mini">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column width="180" prop="nickName" label="用户信息 (可进入用户详情)">
                    <template slot-scope="scope">
                        <div @click="toDetail(scope.row.customerId)">
                            <p class="user-info" style="text-align: center">
                                <el-avatar icon="el-icon-user-solid" :size="30" :src="scope.row.avatar"></el-avatar>
                            </p>
                            <el-link class="user-info">
                                <span style="color: #55a532">● </span>昵称：{{scope.row.nickName || '微信用户'}}
                            </el-link>
                            <el-link class="user-info"><span style="color: #55a532">● </span>手机号：{{scope.row.phone}}
                            </el-link>
                            <el-link class="user-info"><span style="color: #55a532">● </span>会员级别：{{scope.row.userLevel}}
                            </el-link>
                            <el-link class="user-info"><span style="color: #55a532">● </span>总单数：{{scope.row.orderCount}}
                            </el-link>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="末单商品">
                    <template slot-scope="scope">
                        <div class="copy-text" v-if="scope.row.lastProductName.length>0">
                            <p style="margin: 0" v-for="item in scope.row.lastProductName"><span style="color: #e50011;font-size: 10px">● </span>{{item}}</p>
                        </div>

                    </template>
                </el-table-column>
                <el-table-column label="末单商品类型">
                    <template slot-scope="scope">
                        <div class="copy-text" v-if="scope.row.lastOrderProductType.length>0">
                            <p style="margin: 0" v-for="item in scope.row.lastOrderProductType"><span style="color: #55a532;font-size: 10px">● </span>{{item}}</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="lastOrderDate" label="末单/完成 时间" width="160">
                    <template slot-scope="scope">
                        <div v-if="scope.row.lastOrderDate">
                            末单时间：
                            <br/>{{scope.row.lastOrderDate}}
                        </div>
                        <div v-if="scope.row.lastOrderCompleteTime">
                            末单完成时间：
                            <br/>{{scope.row.lastOrderCompleteTime}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="hasCard" label="有无奶卡" width="70">
                    <template slot-scope="scope">
                        <i v-if="scope.row.hasCard=='是'" style="color:#67c23a;font-size: 20px" class="el-icon-success"></i>
                        <i v-else style="color:#f56c6c;font-size: 20px" class="el-icon-error"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="addQiWei" label="是否加微" width="70">
                    <template slot-scope="scope">
                        <i v-if="scope.row.addQiWei=='是'" style="color:#67c23a;font-size: 20px" class="el-icon-success"></i>
                        <i v-else style="color:#f56c6c;font-size: 20px" class="el-icon-error"></i>
                    </template>
                </el-table-column>
                <el-table-column label="所属企微" width="80">
                    <template slot-scope="scope">
                        {{scope.row.qyWeixinUserName || scope.row.qyWeixinUserid}}
                    </template>
                </el-table-column>
                <el-table-column prop="addVTime" label="加企微时间" width="140"></el-table-column>
                <el-table-column prop="userOrderTypeName" label="用户订单类型" width="140">
                </el-table-column>
                <el-table-column label="用户状态" width="90" fixed="right">
                    <template slot-scope="scope">
                        <el-tag size="mini" type="danger" v-if="scope.row.userStatus==10">免打扰</el-tag>
                        <el-tag size="mini" type="warning" v-else-if="scope.row.userStatus==20">自己人</el-tag>
                        <el-tag size="mini" type="success" v-else>正常</el-tag>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" width="90" label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="showSopHistory(scope.row)" type="text">推送日志</el-button>
                        <el-button size="mini" @click="showOrderList(scope.row)" type="text">订单记录</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </tablePagination>
        <sopHistory ref="sopHistory"></sopHistory>
        <orderList ref="orderList"></orderList>

    </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    import sopHistory from "@c/scrm/sopHistory";
    import orderList from "@c/scrm/orderList";

    export default {
        data() {
            return {
                tableHeight: 500,
                loading:true,
                total: 0,
                tableList: [],
                routeList: [],
                userList: [],
                userOrderTypeList:[{id:1,name:'30天内0单用户'},{id:2,name:'30天内1+单用户'},{id:3,name:'30天外0单用户'},{id:4,name:'30天外1+单用户'}],
                showSopInherit: false,
                formParam: {
                    pageSize: 20,
                    currentPage: parseInt(this.$route.query.currentPage)||1,
                    customerId: '',
                    qyWeixinUserid:'',
                    externalUserid: '',
                    nickName:'',
                },
                configInitStatus: 1,
                userList: [],
                groupList: [],
            }
        },
        components: {
            defaultSec, tablePagination,sopHistory,orderList
        },
        beforeCreate() {

        },
        mounted(){
            console.log(this.$route)
            this.initList();
            this.initUserList();
            //表格高度设置  -后面数字根据页面调整
            window.onresize = () => {
                this.tableHeight = document.querySelector('.default-body').offsetHeight - 114
            }
        },
        methods: {
            initList() {
                this.loading = true
                this.$apiGet('lechun-cms/scrmRoute/getCustomerList', this.formParam).then(res => {
                    this.total = res.total;
                    this.loading = false
                    this.tableList = res.list.map(item=>{
                        item = {
                            ...item,
                            lastProductName:item.lastProductName?item.lastProductName.split('),'):[],
                            lastOrderProductType:item.lastOrderProductType?item.lastOrderProductType.split('),'):[],
                        }
                        return item
                    });
                    this.tableHeight = document.querySelector('.default-body').offsetHeight - 114

                })
            },
            initUserList(){
                this.$apiGet('lechun-cms/scrmCalendar/getQwUser', {}).then(res => {
                    console.log(res)
                    this.userList = res
                })
            },
            searchList(){
                this.formParam.currentPage = 1;
                this.$router.push({path:'/customerList',query:{currentPage:1}})
                this.initList()
            },
            exportCustomer(){
                window.open(this.$baseUrl+`/lechun-cms/scrmRoute/exportUnPushCustomerList?day=${this.formParam.day}&customerId=${this.formParam.customerId}&nickName=${this.formParam.nickName}&externalUserid=${this.formParam.externalUserid}&userOrderType=${this.formParam.userOrderType}&pageRouterUrl=prizelist`)
            },
            handlePage(page){
                this.formParam.currentPage = page;
                this.$router.push({path:'/customerList',query:{currentPage:page}})
                this.initList()
            },
            toDetail(id){
                let url = window.location.origin + '/userDetail/' + id
                window.open(url, '_blank')
            },
            /*展示SOP推送历史*/
            showSopHistory(e){
                this.$refs.sopHistory.getSopHistoryList(e.customerId)
            },
            showOrderList(e){
                this.$refs.orderList.getOrderList(e.customerId)
            }
        }
    }
</script>

<style lang="scss" rel="stylesheet/scss">
    .route-customer-wrap {
        .user-info {
            margin: 0;
            cursor: pointer;
            font-size: 12px;
            font-weight: normal;
            display: block;
        }
        .copy-text {
            white-space: pre-line;
            margin: 0;
            line-height: 18px;
            background: #f2f2f2;
            border: 1px solid #e8e8e8;
            border-radius: 5px;
            padding: 6px;
            max-height: 130px;
            overflow-y: scroll;
            scrollbar-width: none;
        }
    }

</style>
